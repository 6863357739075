@import url(./font.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  min-width: 100%;
  min-height: 100vh;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
* {
  box-sizing: border-box;
}

select {
  border: 1px solid #ddd !important;
  font-size: 15 !important;
  min-width: 150px !important;
  padding: 12px !important;
}

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button {
  font-size: 18;
  font-family: "NotoSansKR-Bold";
}
.padding {
  margin: 0px 50px;
}
.row-center {
  display: flex;
  align-items: center;
}
